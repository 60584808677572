.slider__content-top {
  text-align: center;
  margin-bottom: 50px;
}

.slider__wrapper {
  width: 70%;
  margin: auto;
}

.slider__item {
  background: var(--testimonial-bg);
  padding: 40px 30px;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.customer__details {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 40px;
}

.customer__img {
  width: 50px;
  height: 50px;
}

.customer__img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.customer__name {
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
}

@media only screen and (max-width: 992px) {
  .slider__content-top h2 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .slider__wrapper {
    width: 90%;
  }

  .customer__name {
    font-size: 1rem;
  }
}
