#Contact {
  padding: 90px 0;
  background: var(--color-bg1);
  text-align: center;
}
#Contact h2 {
  margin-bottom: 46px;
}
.contact-container {
  margin-top: 40px;
  width: 50%;
}
.container input,
.container textarea
{
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;

  border: 1px solid #ccc; /* Add this line to set the border style */
  border-radius: 10px; /* Optionally, you can add border-radius for rounded corners */
}
form input,
textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--color-black);
  margin-bottom: 1rem;
  outline: none;
  background: transparent;
  border-radius: 12px;
  color: var(--color-black);
  font-size: 14px;
}
/* form .btn:hover {
  border-color: var(--color-hard-blue);
  color: var(--color-hard-blue);
} */

form .btn {
  width: fit-content;
  cursor: pointer;
  display: inline-block;
  padding: 14px 40px;
  color: white;
  background: #0c123d;
  border-radius: 45px;
  border: 2px solid transparent;
  font-weight: 600;
  transition: var(--transition);
}

.contact-container-dark {
  margin-top: 40px;
  width: 50%;

}



.contact-container-dark input,
.contact-container-dark textarea
{
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  color: white;
  border: 1px solid #ccc; /* Add this line to set the border style */
  border-radius: 10px; /* Optionally, you can add border-radius for rounded corners */
}